import React from 'react'
import PropTypes from 'prop-types'
import './PairInfo.css'

class PairInfo extends React.Component {
  render () {
    return (
      <section>
        <h1 className='title'>TV is Linked</h1>
        <div className='check-container success' aria-hidden='true'>
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" enableBackground="new 0 0 96 96" viewBox="0 0 96 96">
            <title>InfoRing / Notification / Check</title>
            <desc>Created with Sketch.</desc>
            <g>
              <g transform="translate(-237.000000, -3364.000000)">
                <g transform="translate(237.000000, 3364.000000)">
                  <g>
                    <g>
                      <g id="Mask">
                        <path d="M48,0c26.5,0,48,21.5,48,48S74.5,96,48,96S0,74.5,0,48S21.5,0,48,0z M48,12c-19.9,0-36,16.1-36,36
                          s16.1,36,36,36s36-16.1,36-36S67.9,12,48,12z" fillRule="evenodd" clipRule="evenOdd" fill="#27B64F" />
                      </g>
                    </g>
                  </g>
                  <g transform="translate(24.000000, 24.000000)">
                    <polygon id="Fill-1" points="38,8 18,28 10,20 4,26 18,40 44,14" fillRule="evenodd" clipRule="evenOdd" fill="#505050" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className='modal--body'>
          <div className='body-text'>TV is linked successfully.<br/>Proceed to the next step on your TV to complete setup.</div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className='add-device' href='' onClick={(e) => this.__addAnotherDeviceClick(e)}>Add another device</a>
      </section>
    )
  }


  __addAnotherDeviceClick (e) {
    e.preventDefault()
    this.props.onAddAnotherDevice(e)
  }
}

PairInfo.propTypes = {
  displayName: PropTypes.string,
  onAddAnotherDevice: PropTypes.func.isRequired
}

export default PairInfo
