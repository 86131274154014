export function post(path, data = undefined) {
  const reqHeaders = new Headers()
  reqHeaders.append('Content-Type', 'application/json')
  reqHeaders.append('Authorization', process.env.REACT_APP_MANAGEMENT_SERVICE_AUTH_TOKEN)

  const url = process.env.REACT_APP_MANAGEMENT_SERVICE_BASEURL + path
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: reqHeaders
  }).catch((err) => {
    console.log(err)
    throw err
  })
}
