import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './PinInput.css'

class PinInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pinChar1: '',
      pinChar2: '',
      pinChar3: '',
      pinChar4: ''
    }
  }

  componentDidMount () {
    this.refs.pin1.focus()
  }

  render () {
    const disabled = this.props.disabled ? 'disabled' : ''

    let cx = classNames(
      'pin-input-group',
      { 'error': this.props.error }
    )

    const cxPin1 = classNames('text', { empty: this.state.pinChar1.length === 0 })
    const cxPin2 = classNames('text', { empty: this.state.pinChar2.length === 0 })
    const cxPin3 = classNames('text', { empty: this.state.pinChar3.length === 0 })
    const cxPin4 = classNames('text', { empty: this.state.pinChar4.length === 0 })

    return (
      <div className={cx}>
        <input type='text' maxLength='1' ref='pin1'
          className={cxPin1}
          value={this.state.pinChar1}
          onChange={(e) => this._onPinChar1Changed(e)}
          disabled={disabled}
        />
        <input type='text' maxLength='1' ref='pin2'
          className={cxPin2}
          value={this.state.pinChar2}
          onChange={(e) => this._onPinChar2Changed(e)}
          disabled={disabled}
        />
        <input type='text' maxLength='1' ref='pin3'
          className={cxPin3}
          value={this.state.pinChar3}
          onChange={(e) => this._onPinChar3Changed(e)}
          disabled={disabled}
        />
        <input type='text' maxLength='1' ref='pin4'
          className={cxPin4}
          value={this.state.pinChar4}
          onChange={(e) => this._onPinChar4Changed(e)}
          disabled={disabled}
        />
      </div>
    )
  }

  _onPinChar1Changed (e) {
    const char = e.target.value
    this.setState({ pinChar1: char.toUpperCase() }, () => {
      this._onPinChanged()
      if (char.length && this.refs.pin2) this.refs.pin2.focus()
    })
  }

  _onPinChar2Changed (e) {
    const char = e.target.value
    this.setState({ pinChar2: char.toUpperCase() }, () => {
      this._onPinChanged()
      if (char.length && this.refs.pin3) this.refs.pin3.focus()
    })
  }

  _onPinChar3Changed (e) {
    const char = e.target.value
    this.setState({ pinChar3: char.toUpperCase() }, () => {
      this._onPinChanged()
      if (char.length && this.refs.pin4) this.refs.pin4.focus()
    })
  }

  _onPinChar4Changed (e) {
    const char = e.target.value
    this.setState({ pinChar4: char.toUpperCase() }, () => {
      this._onPinChanged()
    })
  }

  _onPinChanged () {
    const pin = this.state.pinChar1 + this.state.pinChar2 + this.state.pinChar3 + this.state.pinChar4
    this.props.onChange(pin)
  }
}

PinInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool
}

export default PinInput
