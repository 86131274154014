import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logo from './vizio-logo.png'
import './App.css'
import PinRegistration from '../PinRegistration'
import PairInfo from '../PairInfo'
import Unauthorized from '../Unauthorized'
import Spinner from '../Spinner'
import serviceLocator from '../../services/serviceLocator'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const knownErrors = ['device_does_not_exist', 'device_pin_expired', 'device_name_must_be_unique'];
const authorizeUrl = process.env.REACT_APP_AUTH_PAGE_REDIRECT
const state = serviceLocator.deviceManagementService.getAuthState()
const client_id = process.env.REACT_APP_AUTH_SERVICE_CLIENT_ID
const redirect_uri = serviceLocator.locationService.getLocationOrigin() + '/auth'
const response_type = 'code'
const scope = 'profile'

class App extends Component {
  constructor(props) {
    super(props)
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.0,
      stage: process.env.REACT_APP_STAGE
    });

    this.state = { checkingCode: false, unauthorized: false, authenticated: false, loading: false, success: false }

    if (!props.code) {
      const authPageRedirectUrl = authorizeUrl + '?'
        + 'state=' + state + '&'
        + 'client_id=' + client_id + '&'
        + 'redirect_uri=' + redirect_uri + '&'
        + 'response_type=' + response_type + '&'
        + 'scope=' + scope

      serviceLocator.locationService.redirect(authPageRedirectUrl)
    }
  }

  componentDidMount() {
    if (this.props.code) {
      this.setState({ checkingCode: true })

      return serviceLocator.deviceManagementService.exchangeAuthCodeWithToken(this.props.code, this.props.state, serviceLocator.locationService.getLocationOrigin() + '/auth').then((exchangeResponse) => {
        this.setState({ checkingCode: false, authenticated: true, authToken: exchangeResponse.authToken })
      }).catch((err) => {
        if (err.status !== 401) {
           Sentry.captureMessage(`DMS Client - Failed to exchange auth code for grant: ${err.status}`);
        }
        this.setState({ checkingCode: false, unauthorized: true })
      })
    }
  }

  render() {
    return (
      <div className='wrapper'>
        <div className='oauth-wrapper login'>
          <div className='vizio-logo'>
            <img src={logo} alt='logo' />
          </div>
          <div>
            {this.state.checkingCode && <Spinner />}
            {this.state.unauthorized && <Unauthorized />}
            {this.state.authenticated && !this.state.success && <PinRegistration onSubmit={(data) => this._onSubmitPinRegistration(data)} error={this.state.error} loading={this.state.loading} />}
            {this.state.success && <PairInfo displayName={this.state.displayName} onAddAnotherDevice={() => this._onAddAnotherDevice()}/>}
          </div>
        </div>
        <div className='footer'>&copy; 2019 VIZIO, Inc. All rights reserved.</div>
      </div>
    )
  }

  _onSubmitPinRegistration(data) {
    this.setState({ loading: true, error: undefined, success: false, displayName: undefined })

    return serviceLocator.deviceManagementService.registerDevice(this.state.authToken, data.pin).then((response) => {
      this.setState({ success: true, displayName: response.friendlyName, loading: false })
    }).catch((response) => {
      if(!knownErrors.includes(response.responseCode)) {
        Sentry.captureMessage(`DMS Client - Failed to register device: ${response.message}`)
      }
      this.setState({ success: false, error: response.message, loading: false })
    })
  }

  _onAddAnotherDevice() {
    this.setState({ success: false })
  }
}

App.propTypes = {
  code: PropTypes.string,
  state: PropTypes.string
}

export default App
