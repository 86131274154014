
export default class DeviceManagementService {
  constructor(fetchService) {
    this.fetchService = fetchService
  }

  registerDevice(authToken, pin) {
    return this.fetchService.post('/api/devices/register', {
      authToken,
      id: pin
    }).then((response) => {
      if (response) {
        return response.json().then((data) => {
          if (response.ok) {
            return data
          } else {
            return Promise.reject(data)
          }
        })
      }
      return Promise.resolve()
    })
  }

  exchangeAuthCodeWithToken(authCode, state, redirectUri) {
    return this.fetchService.post('/api/auth/token', {
      authCode,
      state,
      redirectUri
    }).then((response) => {
      if (response) {
        return response.json().then((data) => {
          if (response.ok) {
            return data
          } else {
            return Promise.reject(response)
          }
        })
      }
      return Promise.resolve()
    })
  }

  getAuthState() {
    return this._s4() + this._s4()
  }

  _s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
}
