class LocationService {
  redirect(uri) {
    window.location.replace(encodeURI(uri))
  }

  getLocationOrigin() {
    return window.location.origin
  }
}

export default LocationService