import React, { Component } from 'react'
import './Unauthorized.css'

class Unauthorized extends Component {
  render () {
    return (
      <section>
        <h1 className='title'>Oops</h1>
        <div className='check-container success' aria-hidden='true'>
          <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96;">
            <title>InfoRing / Warning / Exclaim</title>
            <desc>Created with Sketch.</desc>
            <g>
              <g transform="translate(-101.000000, -3956.000000)">
                <g transform="translate(101.000000, 3956.000000)">
                  <g>
                    <g>
                      <g id="Mask">
                        <path d="M48,0c26.5,0,48,21.5,48,48S74.5,96,48,96S0,74.5,0,48S21.5,0,48,0z M48,12c-19.9,0-36,16.1-36,36
                          s16.1,36,36,36s36-16.1,36-36S67.9,12,48,12z" fillRule="evenodd" clipRule="evenodd" fill="#C11111" />
                      </g>
                    </g>
                  </g>
                  <path d="M54,27.1L51.4,54c-0.1,1.1-0.5,1.5-1.7,1.5h-3.7c-1.1,0-1.3-0.4-1.4-1.3l-2.7-26.9c-0.1-0.8,0.4-1.3,1-1.3
                    H53C53.6,26,54,26.4,54,27.1z M53.5,60.8v8.1c0,1.2-0.5,1.6-1.8,1.6h-8.1c-1.1,0-1.5-0.4-1.5-1.4v-8.3c0-0.8,0.5-1.2,1.1-1.2h9.4
                    C53.2,59.6,53.5,60.1,53.5,60.8z" fillRule="evenodd" clipRule="evenodd" fill="#505050"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className='modal--body'>
          Sorry, something went wrong. Close your browser and try again.
        </div>
        <a className='retry' href='/'>Retry</a>
      </section>
    )
  }
}

export default Unauthorized
