import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import './index.css'

const qs = (function (a) {
  if (a === '') return {}
  var b = {}
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split('=', 2)
    if (p.length === 1)
      { b[p[0]] = '' }
    else
      { b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' ')) }
  }
  return b
})(window.location.search.substr(1).split('&'))
const code = qs['code']
const state = qs['state']

ReactDOM.render((<App code={code} state={state} />), document.getElementById('root'))
registerServiceWorker()
