import React, { Component } from 'react'
import './Spinner.css'

class Spinner extends Component {
  render () {
    return (<div className='loader'>Loading...</div>)
  }
}

export default Spinner
