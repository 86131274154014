import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './PinRegistration.css'
import PinInput from '../PinInput'
import Spinner from '../Spinner'

class PinRegistration extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pin: ''
    }
  }

  render () {
    const valid = this.state.pin.length === 4

    return (
      <section>
        <h1 className='header'>Link your TV</h1>
        <p className='body-text'>Enter the code displayed on your TV</p>

        <form onSubmit={(e) => this._onSubmit(e)}>
          <PinInput
            disabled={this.props.loading}
            onChange={(e) => this._onPinChange(e)}
            error={!!this.props.error}
          />
          {this.props.error && <div className='errors'>{this.props.error}</div>}
          {this.props.loading ? (<Spinner />) : <input type='submit' value='Link' disabled={valid ? '' : 'disabled'} className='submit' ref='submit' />}
        </form>
      </section>
    )
  }

  _onPinChange (pin) {
    this.setState({ pin })
    const btn = this.refs.submit
    if (btn && pin.length === 4) {
      this.refs.submit.focus()
    }
  }

  _onSubmit (e) {
    e.preventDefault()
    this.props.onSubmit(this.state)
  }
}

PinRegistration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool
}
export default PinRegistration
