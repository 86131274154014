import DeviceManagementService from './DeviceManagementService'
import LocationService from './LocationService'
import * as fetchService from './fetchService'

const deviceManagementService = new DeviceManagementService(fetchService)
const locationService = new LocationService()

const services = { deviceManagementService, locationService }

export default services
